'use client'

import React from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { Icon } from '@/components/common/Icon'
import { PAGE } from '@/core/constants/pages'
import { useNextAuth } from '@/core/hooks/useNextAuth'
import styles from './Header.module.scss'
import { NavigationMenuButton } from './NavigationMenuButton'
import { NotificationsButton } from './NotificationsButton'
import { ProfileMenuButton } from './ProfileMenuButton'

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const pathname = usePathname()
  const { isLoggedIn } = useNextAuth()

  if (pathname === PAGE.LOGIN || !isLoggedIn) {
    return null
  }

  return (
    <header className={styles.container}>
      <Link href={PAGE.INDEX} className={styles.logo}>
        <Icon name="LogoFullIcon" width="inherit" />
      </Link>
      <div className={styles.right}>
        <NotificationsButton className={styles.button} />
        <ProfileMenuButton />
        <NavigationMenuButton className={styles.button} />
      </div>
    </header>
  )
}
