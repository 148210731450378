import { CurrentUserData } from '@/app/api/user/current/types'
import { UserOrganizationsData } from '@/app/api/user/organizations/types'
import { ROUTE, instance } from './instance'

export const user = {
  current: async () => {
    const { data } = await instance.get<CurrentUserData>(ROUTE.USER.CURRENT)
    return data
  },
  organizations: async () => {
    const { data } = await instance.get<UserOrganizationsData>(ROUTE.USER.ORGANIZATIONS)
    return data
  },
  getLoginPathToOrganization: ({ org_id, redirectUrl }: { org_id: string; redirectUrl?: string }) => {
    const urlParams = new URLSearchParams({ org_id })
    if (redirectUrl) {
      urlParams.append('redirectUrl', redirectUrl)
    }
    return `${ROUTE.USER.SILENT_LOGIN}?${urlParams.toString()}`
  },
}
