import React from 'react'
import { IncidentItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { useLinkPreviewImageData } from '../../../hooks/useLinkPreviewImageData'
import { getIncidentTypeIdColor } from '../../utils/getIncidentTypeColor'
import { TargetCard } from '../TargetCard'

interface TargetIncidentCardProps {
  target: Pick<
    IncidentItem,
    | 'Title'
    | 'IncidentTypeID'
    | 'IncidentTypeName'
    | 'IncidentDateTime'
    | 'Address'
    | 'Latitude'
    | 'Longitude'
    | 'IncidentDescription'
    | 'Hyperlink'
    | '_targetType'
  >
}

export const TargetIncidentCard: React.FC<TargetIncidentCardProps> = ({ target }) => {
  const { data, isLoading } = useLinkPreviewImageData({ link: target.Hyperlink })

  return (
    <div>
      <TargetCard
        details={{
          incidentMarkerColor: getIncidentTypeIdColor(target.IncidentTypeID),
          incidentTypeName: target.IncidentTypeName,
          date: target.IncidentDateTime,
          addressName: target.Address,
          coords: { lat: target.Latitude, lng: target.Longitude },
        }}
        isImageLoading={isLoading}
        imageSrc={data?.image}
        description={target.IncidentDescription}
        link={target.Hyperlink}
      />
    </div>
  )
}
