'use client'

import React from 'react'
import { ErrorInfo } from 'react'
import * as Sentry from '@sentry/nextjs'
import { noop } from 'lodash'
import { ErrorPlaceholder, type ErrorPlaceholderProps } from './ErrorPlaceholder'

export type OnError = (err: Error) => void

export interface ErrorBoundaryProps extends React.PropsWithChildren {
  fallback?: React.ReactNode
  errorHeight?: ErrorPlaceholderProps['height']
  onError?: OnError
}

export interface ErrorBoundaryState {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static defaultProps: ErrorBoundaryProps = {
    fallback: <ErrorPlaceholder />,
    onError: noop,
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true }
  }

  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = { hasError: false }
  }

  override componentDidCatch(err: Error, info: ErrorInfo): void {
    Sentry.captureException(err.message, { extra: { place: 'componentDidCatch', ...info } })
    if (this.props.onError) {
      this.props.onError(err)
    }
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      if (this.props.fallback === ErrorBoundary.defaultProps.fallback) {
        return React.cloneElement(this.props.fallback as any, {
          height: this.props.errorHeight,
        } satisfies ErrorPlaceholderProps)
      }
      return this.props.fallback || null
    }

    return this.props.children
  }
}
