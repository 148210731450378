import React, { useCallback, useState } from 'react'
import { isEmpty } from 'lodash'
import { IncidentItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'

export interface FiltersState {
  incidentTypeIDs: IncidentItem['IncidentTypeID'][]
  severityLevels: IncidentItem['SeverityLevel'][]
  incidentLocations: IncidentItem['Address'][]
}

interface FiltersContextData {
  filters: FiltersState
  applyFilters(newFilters: Partial<FiltersState>): void
  filterQuery: string
  queryId: string
  applyFilterQuery(query: FiltersContextData['filterQuery']): void
  resetFilters(): void
  hasFilters: boolean
}

const DEFAULT_FILTERS_STATE: FiltersState = {
  incidentTypeIDs: [],
  severityLevels: [],
  incidentLocations: [],
}

const FiltersContext = React.createContext<FiltersContextData>({
  filters: { ...DEFAULT_FILTERS_STATE },
  applyFilters: () => null,
  filterQuery: '',
  queryId: '',
  applyFilterQuery: () => null,
  resetFilters: () => null,
  hasFilters: false,
})

export const useFiltersContext = () => React.useContext(FiltersContext)

const getQueryId = () => Date.now().toString()

export const FiltersContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [filters, setFilters] = useState<FiltersState>({ ...DEFAULT_FILTERS_STATE })
  const [{ filterQuery, queryId }, setFilterQuery] = useState({ filterQuery: '', queryId: '' })

  const applyFilters = useCallback((newFilters: Partial<FiltersState>) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...newFilters }))
    setFilterQuery({ filterQuery: '', queryId: getQueryId() })
  }, [])

  const applyFilterQuery = useCallback((query: string) => {
    setFilterQuery({ filterQuery: query, queryId: getQueryId() })
  }, [])

  const resetFilters = useCallback(() => {
    applyFilters({ ...DEFAULT_FILTERS_STATE })
  }, [])

  const hasFilters = !!filterQuery || !isEmpty(Object.values(filters).flat())

  return (
    <FiltersContext.Provider
      value={{
        filters,
        applyFilters,
        filterQuery,
        queryId,
        applyFilterQuery,
        resetFilters,
        hasFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}
