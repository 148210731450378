'use client'

import React from 'react'
import classnames from 'classnames'
import styles from './Icon.module.scss'
import * as icons from './icons'

export enum IconSize {
  s12 = 12,
  s16 = 16,
  s20 = 20,
  s24 = 24,
  s28 = 28,
  s32 = 32,
}

export interface IconProps {
  className?: string
  name: keyof typeof icons
  style?: React.CSSProperties
  size?: IconSize | 12 | 16 | 20 | 24 | 28 | 32
  fillColor?: string | boolean
  width?: number | 'auto' | 'inherit'
  height?: number
  onClick?(): void
}

export const Icon: React.FC<IconProps> = ({
  className,
  name,
  style,
  size = IconSize.s24,
  width = 'auto',
  height = size || IconSize.s24,
  fillColor,
  onClick,
}) => {
  const icon = React.createElement(icons[name], {
    width: size,
    height: size,
    color: typeof fillColor === 'string' ? fillColor : undefined,
  })

  return (
    <span
      style={{ width, height, ...style }}
      className={classnames(
        styles.icon,
        {
          // [styles.size12]: size === 12,
          // [styles.size16]: size === 16,
          // [styles.size20]: size === 20,
          // [styles.size24]: size === 24,
          // [styles.size28]: size === 28,
          // [styles.size32]: size === 32,
          [styles.clickable]: !!onClick,
          [styles.fillColor]: !!fillColor,
        },
        className,
      )}
      onClick={onClick}
    >
      {icon}
    </span>
  )
}
