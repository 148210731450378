import { debounce } from 'lodash'
import type { ExpressionSpecification, GeoJSONSourceSpecification } from 'mapbox-gl'
import { IncidentTypeItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import {
  INCIDENT_TYPE_ID_COLORS,
  getColorId,
  getIncidentTypeIdColor,
} from '../../components/utils/getIncidentTypeColor'
import { MapManager } from '../MapManager'
import { ClusterDonutMarkers, ClusterTypes } from './MapDataDrawer.ClusterDonutMarkers'
import { LAYER, SOURCE, TextColor } from './MapDataDrawer.constants'
import { ImageName, type LatLng, applyStaticEventCoords, createPopupContentElement } from './MapDataDrawer.custom'
import { findCenter } from './MapDataDrawer.utils'

export interface RenderAssetsItem extends LatLng {
  id: number | string
  title: string
  description: string
}

export interface RenderIncidentsItem extends LatLng {
  id: number | string
  incidentTypeId: IncidentTypeItem['IncidentTypeID']
  title: string
  description: string
}

const OPACITY = 0.8
const RE_RENDER_DONUTS_MS = 100

// TODO: Render incidents by grouped dots
// https://docs.mapbox.com/mapbox-gl-js/example/cluster/
// https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/

export class MapDataDrawer {
  map: MapManager | undefined

  setMap = (map: MapManager) => {
    this.map = map
    return this
  }

  hasMap = () => !!this.map

  resetActiveTargets = () => {
    this.map?.setLayoutProperty(LAYER.ASSETS, 'icon-image', ImageName.ASSET_ICON)
  }

  renderAssets = (args: { items: RenderAssetsItem[]; onClick: (item: RenderAssetsItem) => void }) => {
    const source = this.map?.getSource(SOURCE.ASSETS)

    const data: GeoJSONSourceSpecification['data'] = {
      type: 'FeatureCollection',
      features: args.items.map((item) => ({
        type: 'Feature',
        properties: item,
        geometry: {
          type: 'Point',
          coordinates: [item.longitude, item.latitude],
        },
      })),
    }

    if (source) {
      this.map?.updateSourceData(SOURCE.ASSETS, data)
    } else {
      this.map?.addSource(SOURCE.ASSETS, { type: 'geojson', data })
      this.map?.addLayer({
        id: LAYER.ASSETS,
        source: SOURCE.ASSETS,
        type: 'symbol',
        layout: {
          'icon-image': ImageName.ASSET_ICON,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.5, 15, 1],
          'icon-allow-overlap': true,
          'text-field': ['get', 'title'],
          'text-size': 14,
          'text-offset': [0, 2],
          'text-font': ['DIN Pro Regular', 'Arial Unicode MS Regular'],
        },
        paint: {
          'text-color': TextColor.Neutral,
        },
      })

      // Update icon for active selected asset
      const setActiveIcon = (currentPointId: RenderAssetsItem['id']) =>
        this.map
          ?.getMap()
          ?.setLayoutProperty(LAYER.ASSETS, 'icon-image', [
            'match',
            ['get', 'id'],
            currentPointId,
            ImageName.ASSET_ACTIVE_ICON,
            ImageName.ASSET_ICON,
          ])

      this.map
        ?.addLayerMouseEvents(LAYER.ASSETS)
        .on('click', (e) => {
          const properties = e.features![0].properties as RenderAssetsItem
          const currentPoint = properties.id
          args.onClick(properties)
          setActiveIcon(currentPoint)
        })
        .on('mouseover', (e) => {
          const properties = e.features![0].properties as RenderAssetsItem
          applyStaticEventCoords(e, properties) // Make always popup on target coords (not mouse event)
          this.map?.showPopup(e, {
            offset: [0, -12],
            layerId: LAYER.ASSETS,
            content: createPopupContentElement({ title: properties.title }),
          })
        })
    }
  }

  private getIncidentsGeoJsonData = (args: {
    items: RenderIncidentsItem[]
    enrichProps?(item: RenderIncidentsItem): Record<string, unknown>
  }): GeoJSONSourceSpecification['data'] => {
    const data: GeoJSONSourceSpecification['data'] = {
      type: 'FeatureCollection',
      features: args.items.map((item) => ({
        type: 'Feature',
        id: item.id,
        properties: {
          ...item,
          color: getIncidentTypeIdColor(item.incidentTypeId),
          ...(args.enrichProps ? args.enrichProps(item) : null),
        },
        geometry: {
          type: 'Point',
          coordinates: [item.longitude, item.latitude],
        },
      })),
    }

    return data
  }

  renderIncidents = (args: {
    items: RenderIncidentsItem[]
    withCentrify?: boolean
    onClick: (item: RenderIncidentsItem) => void
  }) => {
    const source = this.map?.getSource(SOURCE.INCIDENTS)

    if (args.withCentrify) {
      const { center } = findCenter(args.items)
      this.map?.setCenter({ lng: center.longitude, lat: center.latitude, smooth: true })
    }

    const data = this.getIncidentsGeoJsonData({ items: args.items })

    if (source) {
      this.map?.updateSourceData(SOURCE.INCIDENTS, data)
    } else {
      this.map?.addSource(SOURCE.INCIDENTS, { type: 'geojson', data })
      this.map?.addLayer({
        id: LAYER.INCIDENTS,
        source: SOURCE.INCIDENTS,
        type: 'circle',
        paint: {
          'circle-radius': {
            base: 1.75,
            stops: [
              [12, 4],
              [22, 10],
            ],
          },
          'circle-color': ['get', 'color'],
          'circle-opacity': OPACITY,
        },
      })

      // Assets should be over incidents
      this.map?.moveLayer(LAYER.INCIDENTS, LAYER.ASSETS)

      this.map
        ?.addLayerMouseEvents(LAYER.INCIDENTS)
        .on('click', (e) => {
          const properties = e.features![0].properties as RenderIncidentsItem
          args.onClick(properties)
        })
        .on('mouseover', (e) => {
          const properties = e.features![0].properties as RenderIncidentsItem
          applyStaticEventCoords(e, properties)
          this.map?.showPopup(e, {
            offset: [0, -8],
            layerId: LAYER.INCIDENTS,
            content: createPopupContentElement({ title: properties.title }),
          })
        })
    }
  }

  renderIncidentDonutClusters = (args: {
    items: RenderIncidentsItem[]
    withCentrify?: boolean
    onClick: (item: RenderIncidentsItem, options: { ids: RenderIncidentsItem['id'][] }) => void
  }) => {
    const source = this.map?.getSource(SOURCE.INCIDENT_CLUSTERS)

    if (args.withCentrify) {
      const { center, bounds } = findCenter(args.items)
      this.map?.setCenter({ lng: center.longitude, lat: center.latitude, smooth: true, bounds })
    }

    const data = this.getIncidentsGeoJsonData({
      items: args.items,
      enrichProps: (item) => ({ typeValue: Number(getColorId(item.incidentTypeId)) }),
    })

    console.log(data)

    // colors to use for the categories
    const colors = INCIDENT_TYPE_ID_COLORS

    if (source) {
      this.map?.updateSourceData(SOURCE.INCIDENT_CLUSTERS, data)
    } else {
      // filters for classifying earthquakes into five categories based on magnitude
      const type1 = ['<', ['get', 'typeValue'], 2]
      const type2 = ['all', ['>=', ['get', 'typeValue'], 2], ['<', ['get', 'typeValue'], 3]]
      const type3 = ['all', ['>=', ['get', 'typeValue'], 3], ['<', ['get', 'typeValue'], 4]]
      const type4 = ['all', ['>=', ['get', 'typeValue'], 4], ['<', ['get', 'typeValue'], 5]]
      const type5 = ['all', ['>=', ['get', 'typeValue'], 5], ['<', ['get', 'typeValue'], 6]]
      const type6 = ['all', ['>=', ['get', 'typeValue'], 6], ['<', ['get', 'typeValue'], 7]]
      const type7 = ['all', ['>=', ['get', 'typeValue'], 7], ['<', ['get', 'typeValue'], 8]]
      const type8 = ['all', ['>=', ['get', 'typeValue'], 8], ['<', ['get', 'typeValue'], 9]]
      const type9 = ['>=', ['get', 'typeValue'], 9]

      this.map?.addSource(SOURCE.INCIDENT_CLUSTERS, {
        type: 'geojson',
        data,
        cluster: true,
        clusterRadius: 80,
        clusterMaxZoom: 14,
        clusterProperties: <Record<keyof ClusterTypes, ExpressionSpecification>>{
          // keep separate counts for each magnitude category in a cluster
          type1: ['+', ['case', type1, 1, 0]],
          type2: ['+', ['case', type2, 1, 0]],
          type3: ['+', ['case', type3, 1, 0]],
          type4: ['+', ['case', type4, 1, 0]],
          type5: ['+', ['case', type5, 1, 0]],
          type6: ['+', ['case', type6, 1, 0]],
          type7: ['+', ['case', type7, 1, 0]],
          type8: ['+', ['case', type8, 1, 0]],
          type9: ['+', ['case', type9, 1, 0]],
        },
      })

      const circleColors: ExpressionSpecification = [
        'case',
        type1, getIncidentTypeIdColor('1'),
        type2, getIncidentTypeIdColor('2'),
        type3, getIncidentTypeIdColor('3'),
        type4, getIncidentTypeIdColor('4'),
        type5, getIncidentTypeIdColor('5'),
        type6, getIncidentTypeIdColor('6'),
        type7, getIncidentTypeIdColor('7'),
        type8, getIncidentTypeIdColor('8'),
        getIncidentTypeIdColor('9'),
      ]

      this.map?.addLayer({
        id: LAYER.INCIDENT_CLUSTERS,
        source: SOURCE.INCIDENT_CLUSTERS,
        filter: ['!=', 'cluster', true],
        type: 'circle',
        paint: {
          'circle-color': circleColors,
          'circle-opacity': OPACITY,
          'circle-radius': {
            base: 1.75,
            stops: [
              [12, 5],
              [22, 10],
            ],
          },
        },
      })

      // Assets should be over incidents
      this.map?.moveLayer(LAYER.INCIDENT_CLUSTERS, LAYER.ASSETS)

      const donutMarkers = new ClusterDonutMarkers({
        map: this.map!.getMap()!,
        source: SOURCE.INCIDENT_CLUSTERS,
        layer: LAYER.INCIDENT_CLUSTERS,
      })

      const onRender = debounce(() => {
        if (this.map?.isSourceLoaded(SOURCE.INCIDENT_CLUSTERS)) {
          donutMarkers.render({ opacity: OPACITY, colors })
        }
      }, RE_RENDER_DONUTS_MS)

      this.map?.addMapboxEvents().on('render', onRender)

      this.map
        ?.addLayerMouseEvents(LAYER.INCIDENT_CLUSTERS)
        .on('click', (e) => {
          const properties = e.features![0].properties as RenderIncidentsItem
          const feature = this.map?.queryRenderedFeatures(e.point, { layers: [LAYER.INCIDENT_CLUSTERS] }) || []
          const ids = feature.map((item) => item.id as RenderIncidentsItem['id'])

          args.onClick(properties, { ids })
        })
        .on('mouseover', (e) => {
          const properties = e.features![0].properties as RenderIncidentsItem
          applyStaticEventCoords(e, properties)

          let title = properties.title
          const feature = this.map?.queryRenderedFeatures(e.point, { layers: [LAYER.INCIDENT_CLUSTERS] }) || []

          if (feature.length > 1) {
            title = `There are ${feature.length} incidents`
          }

          this.map?.showPopup(e, {
            offset: [0, -8],
            layerId: LAYER.INCIDENT_CLUSTERS,
            content: createPopupContentElement({ title }),
          })
        })
    }
  }
}
