import React, { useState } from 'react'
import { Icon } from '@/components/common/Icon'
import styles from './CopilotFilterControl.module.scss'
import { DialogPopup } from './DialogPopup'

export const CopilotFilterControl: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDialog = () => setIsOpen((v) => !v)

  return (
    <div className={styles.container}>
      <div className={styles.toggleBtn} onClick={toggleDialog}>
        <Icon name={!isOpen ? 'AiIcon' : 'CloseIcon'} size={24} />
      </div>
      {isOpen && (
        <div className={styles.dialogWrapper}>
          <DialogPopup close={toggleDialog} />
        </div>
      )}
    </div>
  )
}
