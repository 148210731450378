import React from 'react'
import { usePanel } from './usePanel'

const LeftSidePanelContext = React.createContext<ReturnType<typeof usePanel>>({
  panel: null,
  setPanel: () => null,
  closePanel: () => null,
})

export const useLeftSidePanelContext = () => React.useContext(LeftSidePanelContext)

export const LeftSidePanelContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const value = usePanel()

  return <LeftSidePanelContext.Provider value={value}>{children}</LeftSidePanelContext.Provider>
}
