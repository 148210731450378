import React from 'react'
import classnames from 'classnames'
import styles from './ErrorPlaceholder.module.scss'

export interface ErrorPlaceholderProps {
  className?: string
  title?: string
  height?: React.CSSProperties['height']
}

export const ErrorPlaceholder: React.FC<ErrorPlaceholderProps> = ({
  className,
  height,
  title = 'Something went wrong',
}) => (
  <div className={classnames(styles.container, 'flex items-center justify-center p-4', className)} style={{ height }}>
    {title}
  </div>
)
