import sizes from './variables.module.scss'

type Sizes = {
  headerHeight: string
  leftSidebarWidth: string
  baseLeftPanelWidth: string
  mapViewLeft: string
}

export const Sizes = sizes as Sizes
