import { useEffect, useMemo } from 'react'
import * as Sentry from '@sentry/browser'
import { CurrentUserModel } from '../models/CurrentUserModel'
import { useNextAuth } from './useNextAuth'

export const useCurrentUser = () => {
  const { isLoggedIn, session } = useNextAuth()

  const userModel = useMemo(() => new CurrentUserModel(session?.user), [session])

  useEffect(() => {
    if (isLoggedIn) {
      Sentry.setUser({
        email: userModel.email,
        username: userModel.name,
      })
    }
  }, [isLoggedIn, userModel])

  return {
    isLoggedIn,
    orgId: userModel.org_id,
    user: userModel,
  }
}
