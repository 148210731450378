import { useQuery } from '@tanstack/react-query'
import { Api } from '@/core/api'
import { useCurrentUser } from '@/core/hooks/useCurrentUser'

export const QUERY_KEY_ASSETS = 'map-assets'

export const useAssetsData = () => {
  const { orgId } = useCurrentUser()
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEY_ASSETS, orgId],
    queryFn: () => Api.mapEvents.getAssets({ orgId }),
    placeholderData: [],
  })

  return { data, assetsData: data, ...query }
}
