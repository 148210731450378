import React from 'react'
import { Spinner, SpinnerProps } from '@chakra-ui/react'
import classNames from 'classnames'
import styles from './Loader.module.scss'

interface LoaderProps extends Pick<SpinnerProps, 'size'> {
  className?: string
  relative?: boolean
  withBackground?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

export const Loader: React.FC<LoaderProps> = ({ className, relative, withBackground, size }) => {
  return (
    <div className={classNames(styles.container, className, { [styles.relative]: relative })}>
      {withBackground && <div className={styles.background} />}
      <Spinner speed="0.75s" size={size} color="var(--m-Neutral-20)" />
    </div>
  )
}
