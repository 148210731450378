'use client'

import { useEffect } from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { useToast } from '@/components/hooks/useToast'

const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again.'

export const useErrorToast = (
  query: Pick<UseQueryResult, 'error' | 'isError'>,
  options?: {
    invokeError?: (err: Error | null) => string | null
  },
) => {
  const toast = useToast()

  useEffect(() => {
    if (query.error) {
      let description = DEFAULT_ERROR_MESSAGE
      if (typeof options?.invokeError === 'function') {
        const value = options.invokeError(query.error)
        description = (value && typeof value === 'object' ? JSON.stringify(value) : value) || description
      }

      toast({ description, status: 'error' })
    }
  }, [query.error])
}
