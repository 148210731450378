/* eslint-disable @typescript-eslint/ban-ts-comment */
import { debounce } from 'lodash'
import { type LayerSpecification, type MapEventType, type MapMouseEvent, type PopupOptions } from 'mapbox-gl'
import * as mapbox from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Env } from '@/utils/env'
import { LatLng, customizeMap } from '../MapDataDrawer/MapDataDrawer.custom'
import { MapSearch } from '../MapSearch'
import styles from './MapManager.module.scss'
import { config, getDefaultCoords } from './MapManager.utils'

type MouseEventListener = (ev: MapMouseEvent) => void
type MapboxEventListener = Parameters<mapbox.Map['on']>[2]

const MAP_STYLE = {
  DEFAULT: 'mapbox://styles/mapbox/streets-v12',
  MONOCHROME: 'mapbox://styles/mpsk/cly69nzmv00cg01pibxwudsb3',
  MONOCHROME_PURPLE: 'mapbox://styles/mpsk/clye7cz5q00p701pmd2z05prr',
  MONOCHROME_PURPLE_O: 'mapbox://styles/ostapvykhopen/cm36djtaz00fk01o09a2faerh',
}

/**
 * README:
 * Styles - https://docs.mapbox.com/api/maps/styles/#classic-mapbox-styles
 */

const MAP_BOX_TOKEN = process.env.MAP_BOX_TOKEN as string
const MAP_BOX_SEARCH_TOKEN = MAP_BOX_TOKEN

export class MapManager {
  private map: mapbox.Map | null = null
  private searchBox: MapSearch | null = null
  private _element: HTMLDivElement

  constructor({ containerElement }: { containerElement: HTMLDivElement }) {
    this._element = containerElement
  }

  async init() {
    if (this.map) return this

    const map = await new Promise<mapbox.Map>((resolve) => {
      const mapInstance = new mapbox.Map({
        accessToken: MAP_BOX_TOKEN,
        container: this._element,
        projection: { name: 'mercator', center: [getDefaultCoords().center.lng, getDefaultCoords().center.lat] },
        style: MAP_STYLE.MONOCHROME_PURPLE_O, //
        center: [getDefaultCoords().center.lng, getDefaultCoords().center.lat],
        zoom: getDefaultCoords().zoom,
      })

      const stateViewChange = debounce(() => {
        const zoom = mapInstance.getZoom()
        const center = mapInstance.getCenter()
        config.saveView({ zoom, center })
      }, 300)

      mapInstance
        .on('zoomend', stateViewChange)
        .on('dragend', stateViewChange)
        .on('load', () => {
          resolve(mapInstance)
          customizeMap(mapInstance)
        })
    })

    this.map = map

    if (Env.isDev()) {
      Object.assign(window, { __map: this.map })
    }

    this.initSearchBox()

    return this
  }

  initSearchBox = async () => {
    this.searchBox = new MapSearch({ map: this.map! }).init({
      accessToken: MAP_BOX_SEARCH_TOKEN,
      options: { language: 'en', types: 'country,region,postcode,district,place,city,street,address' },
    })
  }

  addEventListener = (event: MapEventType | MapEventType[], listener: MapboxEventListener) => {
    const events = Array.isArray(event) ? event : [event]
    events.forEach((ev) => this.map?.on(ev, listener))
    return {
      dispose: () => {
        events.forEach((ev) => this.map?.off(ev, listener))
      },
    }
  }

  resize = debounce(() => this.map?.resize(), 100)

  getMap = () => this.map
  getBounds = (): { ne: LatLng; sw: LatLng } | undefined => {
    const bounds = this.map?.getBounds()
    const getCoords = (latLng: mapbox.LngLat): LatLng => ({ latitude: latLng.lat, longitude: latLng.lng })

    return bounds
      ? {
          ne: getCoords(bounds._ne),
          sw: getCoords(bounds._sw),
        }
      : undefined
  }
  setCenter = ({
    lat,
    lng,
    smooth,
    bounds,
  }: {
    lng?: number
    lat?: number
    smooth?: boolean
    bounds?: { ne: LatLng; sw: LatLng }
  }) => {
    if (Number.isNaN(lat) || Number.isNaN(lng)) {
      return
    }

    if (bounds) {
      this.map?.fitBounds(
        [
          [bounds.sw.longitude, bounds.sw.latitude],
          [bounds.ne.longitude, bounds.ne.latitude],
        ],
        {
          center: lat && lng ? { lat: lat!, lng: lng! } : undefined,
          duration: 600,
        },
      )
    } else if (smooth) {
      this.map?.flyTo({ center: { lat: lat!, lng: lng! } })
    } else {
      this.map?.setCenter({ lat: lat!, lng: lng! })
    }
  }

  // Source
  addSource = (sourceId: string, source: mapbox.GeoJSONSourceSpecification) => this.map?.addSource(sourceId, source)
  getSource = (sourceId: string) => this.map?.getSource(sourceId)
  isSourceLoaded = (sourceId: string) => this.map?.isSourceLoaded(sourceId)
  updateSourceData = (sourceId: string, data: mapbox.GeoJSONSourceSpecification['data']) => {
    // @ts-ignore // "setData" does not exists in @types/mapbox-gl@3.1.0
    this.getSource(sourceId)?.setData(data)
  }
  removeSource = (sourceId: string) => {
    if (this.getSource(sourceId)) {
      this.map?.removeSource(sourceId)
    }
  }

  // Layer
  addLayer = (layer: LayerSpecification) => this.map?.addLayer(layer)
  getLayer = (layerId: string) => this.map?.getLayer(layerId)
  removeLayer = (layerId: string) => {
    if (this.getLayer(layerId)) {
      this.map?.removeLayer(layerId)
    }
  }
  moveLayer = (backLayerId: string, topLayerId: string) => {
    if (this.getLayer(backLayerId) && this.getLayer(topLayerId)) {
      this.map?.moveLayer(backLayerId, topLayerId)
    }
  }
  setLayoutProperty: mapbox.Map['setLayoutProperty'] = (layerId: string, ...args) => {
    if (this.getLayer(layerId)) {
      this.map?.setLayoutProperty(layerId, ...args)
    }
    return this.map!
  }

  addMapboxEvents = () => {
    const api = {
      on: (event: MapEventType, listener: MapboxEventListener) => {
        this.map?.on(event, listener)
        return api
      },
    }
    return api
  }

  removeMapboxEvents = () => {
    const api = {
      off: (event: MapEventType, listener: MapboxEventListener) => {
        this.map?.off(event, listener)
        return api
      },
    }
    return api
  }

  addLayerMouseEvents = (layerId: string) => {
    const api = {
      on: (event: MapEventType, listener: MouseEventListener) => {
        if (this.getLayer(layerId)) {
          this.map?.on(event, layerId, listener as any)
          const map = this.map!
          if (map && event === 'mouseover') {
            map
              .on('mouseover', layerId, () => (map.getCanvas().style.cursor = 'pointer'))
              .on('mouseleave', layerId, () => (map.getCanvas().style.cursor = ''))
          }
        }
        return api
      },
    }
    return api
  }

  removeLayerMouseEvents = (layerId: string) => {
    const api = {
      off: (event: MapEventType, listener: MouseEventListener) => {
        if (this.getLayer(layerId)) {
          this.map?.off(event, layerId, listener as any)
        }
        return api
      },
    }
    return api
  }

  queryRenderedFeatures = (point: mapbox.PointLike, options: { layers: string[] }) => {
    return this.map ? this.map.queryRenderedFeatures(point, options) : []
  }

  // Own API
  showPopup = (
    e: mapbox.MapMouseEvent,
    data: { layerId: string; content: HTMLElement | string; offset?: PopupOptions['offset'] },
  ) => {
    const popup = new mapbox.Popup({
      className: styles.mapboxPopup,
      offset: data.offset || [0, -4],
      closeOnMove: true,
      closeButton: false,
    }).setLngLat(e.lngLat)

    if (data.content instanceof HTMLElement) {
      popup.setHTML(data.content.innerHTML)
    } else {
      popup.setText(data.content)
    }

    popup.addTo(this.map!)

    const hidePopup = () => popup.remove()
    this.map?.on('mouseleave', data.layerId, hidePopup)
  }
}
