import React, { useEffect } from 'react'
import classnames from 'classnames'
import { AsidePanel, type AsidePanelProps } from '@/components/common/AsidePanel'
import { CloseButton } from '@/components/common/Button'
import { useMapContext } from '../../context/MapContextProvider'
import styles from './BasePanel.module.scss'

export interface BasePanelProps extends Pick<AsidePanelProps, 'className'> {
  title: string
  controls?: React.ReactNode
  animated?: AsidePanelProps['animated']
  moveMap?: boolean
  onClickClose?(): void
}

export const BasePanel: React.FC<React.PropsWithChildren<BasePanelProps>> = ({
  className,
  title,
  controls,
  animated,
  moveMap,
  onClickClose,
  children,
}) => {
  const { map, moveMapAside } = useMapContext()

  useEffect(() => {
    const shouldResize = map && moveMap
    if (shouldResize) {
      moveMapAside(true)
      map.resize()
    }
    return () => {
      if (shouldResize) {
        moveMapAside(false)
        map.resize()
      }
    }
  }, [map, moveMap])

  return (
    <AsidePanel className={classnames(styles.container, className)} position="left" animated={animated}>
      <div className={styles.titleWrapper}>
        <div className="flex items-center">
          {onClickClose && <CloseButton className={styles.closeBtn} onClick={onClickClose} />}
          <div className={styles.title}>{title}</div>
        </div>
        {controls && <div className="flex items-center">{controls}</div>}
      </div>
      <div className={styles.body}>{children}</div>
    </AsidePanel>
  )
}
