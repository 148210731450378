import { IncidentTypeItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { Colors } from '@/styles/colors'

export type IncidentTypeIdColor = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'

// https://colorany.com/color-palette-generator/
const INCIDENT_TYPE_COLOR: Record<IncidentTypeIdColor, string> = {
  '1': '#EC281C',
  '2': '#F17833',
  '3': '#d25b5b',
  '4': '#8465DE',
  '5': '#D8DB35',
  '6': '#3498db',
  '7': '#27ae60',
  '8': '#aed6f1',
  '9': '#ccd1d1',
}

export const INCIDENT_TYPE_IDS = [...Object.keys(INCIDENT_TYPE_COLOR)] as IncidentTypeIdColor[]
export const INCIDENT_TYPE_ID_COLORS = [...Object.values(INCIDENT_TYPE_COLOR)]

export const getColorId = (typeId: IncidentTypeItem['IncidentTypeCategory']): IncidentTypeIdColor =>
  String(parseInt(typeId)) as IncidentTypeIdColor

export const getIncidentTypeIdColor = (typeId: IncidentTypeItem['IncidentTypeCategory'] | IncidentTypeIdColor) => {
  const rootTypeId = getColorId(typeId)
  return INCIDENT_TYPE_COLOR[rootTypeId] || Colors.White
}
