const LOCALE = 'en-US'

export const getTimeDate = (
  date: string,
  options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
) => new Intl.DateTimeFormat(LOCALE, options).format(new Date(date))

export const getRelativeTimeAgo = (date: string) => {
  const cutoffs = [60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity]
  const units: Intl.RelativeTimeFormatUnit[] = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year']

  const deltaSeconds = Math.round((new Date(date).valueOf() - Date.now()) / 1000)
  const unitIndex = cutoffs.findIndex((cutoff) => cutoff > Math.abs(deltaSeconds))
  const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1

  return new Intl.RelativeTimeFormat(LOCALE, { numeric: 'auto' }).format(
    Math.floor(deltaSeconds / divisor),
    units[unitIndex],
  )
}
