import { useEffect, useState } from 'react'

export type ObservableRef = React.RefObject<Element>

export interface UseIntersectionObserverOptions extends IntersectionObserverInit {
  once?: boolean
}

export type UseIntersectionObserverResult = IntersectionObserverEntry | null

export const useIntersectionObserver = (
  observableRef: ObservableRef,
  { threshold = 0, root = null, rootMargin = '0%', once = false }: UseIntersectionObserverOptions = {},
): UseIntersectionObserverResult => {
  const [resultEntry, setResultEntry] = useState<UseIntersectionObserverResult>(null)

  const frozen = Boolean(once && resultEntry && resultEntry.isIntersecting)

  const updateEntry: IntersectionObserverCallback = ([entry]) => setResultEntry(entry)

  useEffect(() => {
    const observableElement = observableRef.current

    if (frozen || !observableElement) {
      return
    }

    const observerParams = { threshold, root, rootMargin }
    const observer = new IntersectionObserver(updateEntry, observerParams)

    observer.observe(observableElement)

    return () => observer.disconnect()
  }, [
    // eslint-disable-line react-hooks/exhaustive-deps
    observableRef,

    // since threshold can be an array,
    // but useEffect uses `Object.is` for its comparison algorithm: https://beta.reactjs.org/apis/react/useEffect#parameters,
    // we need to convert it to be a primitive value
    // to skip unnecessary IntersectionObserver instance re-creations
    // ----
    // this ESLint error can be prevented in the future if we have one of these hooks:
    // - https://github.com/streamich/react-use/blob/master/docs/useCustomCompareEffect.md
    // - https://github.com/streamich/react-use/blob/master/docs/useShallowCompareEffect.md
    JSON.stringify(threshold), // eslint-disable-line react-hooks/exhaustive-deps
    root,
    rootMargin,
    frozen,
    threshold,
  ])

  return resultEntry
}
