import React from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Sizes } from '@/styles/sizes'
import { useMapContext } from '../context/MapContextProvider'
import './MapView.scss'
import { MAPBOX_CONTAINER_ID, MAPBOX_SEARCH_CONTAINER_ID } from './constants'

const LEFT = Sizes.mapViewLeft
const WIDTH = `calc(100% - ${LEFT})`
const POSITION = { width: WIDTH, left: LEFT, right: 0 }

export const MapBoxView = () => {
  const { mapRefEl, moveRight } = useMapContext()

  return (
    <>
      <div
        id={MAPBOX_CONTAINER_ID}
        ref={mapRefEl}
        style={{ height: 'inherit', width: 'auto', ...(moveRight ? POSITION : {}) }}
      />
      <div id={MAPBOX_SEARCH_CONTAINER_ID} />
    </>
  )
}
