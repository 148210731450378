import { FeedQueryParams } from '@/pages/api/map-events/get-incidents-feed.types'
import { IncidentLocations } from '@/pages/api/map-events/incident-locations.types'
import { GetIncidentsQueryData } from '@/pages/api/map-events/query-incidents.types'
import {
  AssetItem,
  IncidentFeedItem,
  IncidentItem,
  IncidentTypeItem,
  OrgParams,
  SeverityItem,
} from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { ROUTE, instance } from './instance'

export const mapEvents = {
  getAssets: async ({ orgId }: OrgParams) => {
    const { data } = await instance.get<AssetItem[]>(ROUTE.MAP_EVENTS.ASSETS, { params: { orgId } })
    return data
  },

  getIncidents: async ({ orgId }: OrgParams) => {
    const { data } = await instance.get<IncidentItem[]>(ROUTE.MAP_EVENTS.INCIDENTS, { params: { orgId } })
    return data
  },

  getIncidentsByQuery: async ({ query, org_id }: GetIncidentsQueryData) => {
    const { data } = await instance.post<IncidentItem[]>(
      ROUTE.MAP_EVENTS.INCIDENTS_QUERY,
      { query, org_id },
      { timeout: 90 * 1000 },
    )
    return data
  },

  getIncidentsFeed: async ({ orgId, offset, limit, coordBounds, filters }: OrgParams & FeedQueryParams) => {
    const { data } = await instance.get<IncidentFeedItem[]>(ROUTE.MAP_EVENTS.INCIDENTS_FEED, {
      params: { orgId, offset, limit, coordBounds, filters },
    })
    return data
  },

  getIncidentsCount: async ({
    orgId,
    coordBounds,
    filters,
  }: OrgParams & Partial<Pick<FeedQueryParams, 'coordBounds' | 'filters'>>) => {
    const { data } = await instance.get<{ count: number }>(ROUTE.MAP_EVENTS.INCIDENTS_COUNT, {
      params: { orgId, coordBounds, filters },
    })
    return data
  },

  getIncidentLocations: async ({ orgId }: OrgParams) => {
    const { data } = await instance.get<IncidentLocations>(ROUTE.MAP_EVENTS.INCIDENT_LOCATIONS, { params: { orgId } })
    return data
  },

  getIncidentTypes: async ({ orgId }: OrgParams) => {
    const { data } = await instance.get<IncidentTypeItem[]>(ROUTE.MAP_EVENTS.INCIDENT_TYPES, { params: { orgId } })
    return data
  },

  getSeverityLevels: async ({ orgId }: OrgParams) => {
    const { data } = await instance.get<SeverityItem[]>(ROUTE.MAP_EVENTS.SEVERITY_LEVELS, { params: { orgId } })
    return data
  },

  /** @deprecated */
  getTargetPhoto: async (data: { assetId?: AssetItem['AssetID'] }) => {
    const url = instance.getUri({ url: ROUTE.MAP_EVENTS.TARGET_PHOTO, params: { ...data } })
    return fetch(url, { method: 'GET' })
      .then((r) => {
        const contentType = r.headers.get('Content-Type')
        if (contentType === 'application/octet-stream') {
          return r.blob()
        }
        return null
      })
      .then((blob) => {
        const objectURL = blob ? URL.createObjectURL(blob) : undefined
        return { imageUrl: objectURL }
      })
  },
}
