import { mapValues } from 'lodash'
import * as mapbox from 'mapbox-gl'

export enum ImageName {
  ASSET_ICON = 'ASSET_ICON',
  ASSET_ACTIVE_ICON = 'ASSET_ACTIVE_ICON',
}

export interface LatLng {
  latitude: number
  longitude: number
}

const escapePublicPath = (record: Record<ImageName, string>) =>
  mapValues(record, (value) => value.replace('/public', ''))

const IMAGE_PUBLIC_PATH: Record<ImageName, string> = escapePublicPath({
  [ImageName.ASSET_ICON]: '/public/map/asset-icon.png',
  [ImageName.ASSET_ACTIVE_ICON]: '/public/map/asset-active-icon.png',
})

const loadImage = ({ map, imageName }: { map: mapbox.Map; imageName: ImageName }) => {
  if (!map.hasImage(imageName)) {
    map.loadImage(IMAGE_PUBLIC_PATH[imageName], (err, image) => {
      if (err) throw err
      if (image) {
        map.addImage(imageName, image, { sdf: false })
      }
    })
  }
}

export const customizeMap = (map: mapbox.Map) => {
  Object.keys(ImageName).forEach((imageName) => {
    loadImage({ map, imageName: imageName as ImageName })
  })
}

export const createPopupContentElement = (data: { title: string; description?: string }) => {
  const div = document.createElement('div')
  const titleEl = document.createElement('div')
  titleEl.innerHTML = data.title
  div.appendChild(titleEl)
  if (data.description) {
    const descEl = document.createElement('div')
    descEl.innerHTML = data.description
    descEl.style.fontSize = '12px'
    div.appendChild(descEl)
  }
  return div
}

export const applyStaticEventCoords = (e: mapbox.MapMouseEvent, properties: LatLng): mapbox.MapMouseEvent => {
  const targetLatLng: Pick<mapbox.MapMouseEvent['lngLat'], 'lat' | 'lng'> = {
    lat: properties.latitude,
    lng: properties.longitude,
  }
  Object.assign(e.lngLat, targetLatLng) // Make always popup on target coords (not mouse event)
  return e
}
