import React from 'react'
import { TargetType } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { ActiveTarget, useMapEventsContext } from '../../context/MapEventsContextProvider'
import { RightDrawer } from '../RightDrawer'
import { TargetAssetCard } from '../cards/TargetAssetCard'
import { TargetIncidentCard } from '../cards/TargetIncidentCard'

const targetIs = {
  Asset: (target: ActiveTarget | null): target is React.ComponentProps<typeof TargetAssetCard>['target'] =>
    target?._targetType === TargetType.Asset,
  Incident: (target: ActiveTarget | null): target is React.ComponentProps<typeof TargetIncidentCard>['target'] =>
    target?._targetType === TargetType.Incident,
}

export const RightSidebarDrawer = () => {
  const { activeTarget, setActiveTarget } = useMapEventsContext()

  if (targetIs.Asset(activeTarget)) {
    return (
      <RightDrawer
        headerTitle={activeTarget.AssetName}
        contentBody={<TargetAssetCard target={activeTarget} />}
        onClose={() => setActiveTarget(null)}
      />
    )
  }

  if (targetIs.Incident(activeTarget)) {
    return (
      <RightDrawer
        headerTitle={activeTarget.Title}
        contentBody={<TargetIncidentCard target={activeTarget} />}
        onClose={() => setActiveTarget(null)}
      />
    )
  }

  return null
}
