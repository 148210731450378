import React, { useCallback } from 'react'
import { noop } from 'lodash'
import { PanelProps } from '../../../context/LeftSidePanelContextProvider/panel.types'
import { useMapEventsContext } from '../../../context/MapEventsContextProvider'
import { BasePanel } from '../BasePanel'
import { IncidentFeedItemView, IncidentsFeedCount } from '../IncidentsFeedPanel'

interface ActiveIncidentsPanelProps extends PanelProps {}

export const ActiveIncidentsPanel: React.FC<ActiveIncidentsPanelProps> = ({ animated, closePanel = noop }) => {
  const { activeIncidents, setActiveIncidents } = useMapEventsContext()

  const onClosePanel = useCallback(() => {
    setActiveIncidents(null)
    closePanel()
  }, [])

  return (
    <BasePanel
      className="ActiveIncidentsPanel"
      title="Selected Incidents"
      onClickClose={onClosePanel}
      animated={animated}
      controls={<IncidentsFeedCount count={Number(activeIncidents?.length)} />}
    >
      <div>{activeIncidents?.map((item) => <IncidentFeedItemView key={item.IncidentID} item={item} />)}</div>
    </BasePanel>
  )
}
