import { useQuery } from '@tanstack/react-query'
import { Api } from '@/core/api'

export const QUERY_KEY_CURRENT_USER_ORGS = 'current-user-organizations-data'

export const useCurrentUserOrganizationsData = () => {
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEY_CURRENT_USER_ORGS],
    queryFn: () => Api.user.organizations(),
  })

  return { data, ...query }
}
