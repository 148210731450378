import { useEffect } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { useErrorToast } from '@/components/layers/ReactQueryProvider'
import { Api, isAxiosError } from '@/core/api'
import { useCurrentUser } from '@/core/hooks/useCurrentUser'

export const QUERY_KEY_INCIDENTS_QUERY = 'incidents-query'

export const useIncidentsByQueryData = ({ enabled = true, question = '', queryId = '' } = {}) => {
  const { orgId } = useCurrentUser()
  const client = useQueryClient()
  const { data = [], ...query } = useQuery({
    queryKey: [QUERY_KEY_INCIDENTS_QUERY, orgId, question, queryId],
    queryFn: () => Api.mapEvents.getIncidentsByQuery({ query: question, org_id: orgId }),
    placeholderData: [],
    enabled: enabled || !!question,
  })

  useErrorToast(query, {
    invokeError: (err) => {
      if (isAxiosError(err)) {
        return err.response?.data?.details?.detail || err.response?.data?.message || null
      }
      return null
    },
  })

  useEffect(() => {
    if (query.isError) {
      client.removeQueries({ queryKey: [QUERY_KEY_INCIDENTS_QUERY, question, queryId], exact: true })
    }
  }, [query.isError, queryId])

  return { data, incidentsData: isEmpty(data) ? [] : data!, ...query }
}
