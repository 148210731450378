import React from 'react'
import { FiltersContextProvider } from './FiltersContextProvider'
import { LeftSidePanelContextProvider } from './LeftSidePanelContextProvider'
import { MapContextProvider } from './MapContextProvider'
import { MapEventsContextProvider } from './MapEventsContextProvider'

export const ContextsProviders: React.FC<React.PropsWithChildren> = ({ children }) => (
  <MapContextProvider>
    <FiltersContextProvider>
      <LeftSidePanelContextProvider>
        <MapEventsContextProvider>{children}</MapEventsContextProvider>
      </LeftSidePanelContextProvider>
    </FiltersContextProvider>
  </MapContextProvider>
)
