import React from 'react'
import { MenuItem } from '@chakra-ui/react'
import { Organization } from '@/server/services/Auth0/Auth0.types'

/**
 * README:
 * https://community.auth0.com/t/auth0-nextjs-silent-authentication-during-action-flow/84848
 *
 * We want to auth/redirect on selected org id
 */

interface OrganizationItemProps {
  className: string
  item: Organization
  isSelected: boolean
  onClick: (org_id: Organization['id']) => void
}

const OrganizationItem: React.FC<OrganizationItemProps> = ({ className, item, isSelected, onClick }) => (
  <MenuItem
    className={className}
    style={isSelected ? { color: 'white' } : {}}
    disabled={isSelected}
    onClick={() => onClick(item.id)}
  >
    <div>{item.display_name}</div>
  </MenuItem>
)

export const OrganizationsList: React.FC<{
  menuItemClassName: string
  items: Organization[]
  selectedItemId: Organization['id']
  onItemClick: OrganizationItemProps['onClick']
}> = ({ menuItemClassName, items, selectedItemId, onItemClick }) => (
  <div>
    {items.map((item) => (
      <OrganizationItem
        key={item.id}
        className={menuItemClassName}
        item={item}
        onClick={onItemClick}
        isSelected={item.id === selectedItemId}
      />
    ))}
  </div>
)
