import React from 'react'
import { Loader } from '@/components/common/Loader'
import { lazyComponent } from '@/components/common/utils/lazyImport'
import { BasePanel } from '../BasePanel'

export const LazyIncidentsFeedPanel = lazyComponent(
  () => import(/* webpackChunkName: 'IncidentsFeedPanel' */ './IncidentsFeedPanel'),
  {
    preload: true,
    loading: () => (
      <BasePanel title="Feed" onClickClose={() => null}>
        <Loader />
      </BasePanel>
    ),
  },
)
