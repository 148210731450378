/* eslint-disable @typescript-eslint/ban-ts-comment */
import { LngLatLike, Map as MapboxMap, Marker } from 'mapbox-gl'
import { Colors } from '@/styles/colors'
import { montserratFontFamily } from '@/styles/fonts'

export type ClusterTypes = {
  type1: number
  type2: number
  type3: number
  type4: number
  type5: number
  type6: number
  type7: number
  type8: number
  type9: number
}
type ClusterData = { cluster: boolean; cluster_id: number; point_count: number; point_count_abbreviated: string }

/**
 * README:
 * Example: https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/
 */
export class ClusterDonutMarkers {
  private markers: Record<string, Marker> = {}
  private markersOnScreen: Record<string, Marker> = {}
  private map: MapboxMap
  private source: string
  private layer: string

  constructor({ map, source, layer }: { map: MapboxMap; source: string; layer: string }) {
    this.map = map
    this.source = source
    this.layer = layer
  }

  render = ({ opacity, colors }: { colors: string[]; opacity: number }) => {
    const newMarkers: Record<string, Marker> = {}
    type Feature = GeoJSON.Feature<
      // @ts-ignore
      { coordinates: LngLatLike },
      { cluster_id: string; cluster: boolean; point_count: number; point_count_abbreviated: number } & ClusterTypes
    >
    const features: Feature[] = this.map.querySourceFeatures(this.source, { sourceLayer: this.layer }) as any
    // const renderedFeatures = this.map.queryRenderedFeatures(undefined, { layers: [this.layer] })
    // console.log('=== renderedFeatures', { renderedFeatures, features })

    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (const feature of features) {
      const coords = feature.geometry.coordinates
      const props: ClusterTypes & ClusterData = feature.properties as any
      if (!props.cluster) continue

      const id = props.cluster_id
      let marker = this.markers[id]
      if (!marker) {
        const el = this.create(props as any, { colors, opacity })
        marker = this.markers[id] = new Marker({ element: el }).setLngLat(coords)

        // el.addEventListener('click', () => {
        //   console.log('=== click', { feature })
        // })
      }
      newMarkers[id] = marker

      if (!this.markersOnScreen[id]) {
        try {
          marker.addTo(this.map)
        } catch (err) {
          console.error(err)
        }
      }
    }
    // for every marker we've added previously, remove those that are no longer visible
    for (const id in this.markersOnScreen) {
      if (!newMarkers[id]) {
        this.markersOnScreen[id].remove()
      }
    }

    this.markersOnScreen = newMarkers
  }

  // code for creating an SVG donut chart from feature properties
  private create = (
    props: ClusterTypes & ClusterData,
    { colors, opacity = 1 }: { colors: string[]; opacity?: number },
  ) => {
    const offsets = []
    const typeCounts = Object.keys(props).reduce((acc, key: keyof ClusterTypes | string) => {
      if (key.startsWith('type') && Number.isInteger(parseInt(key.replace('type', '')))) {
        acc.push(props[key as keyof ClusterTypes])
      }
      return acc
    }, [] as number[])
    const pointCounts = String(props.point_count_abbreviated).replace(',', '.')
    let total = 0
    for (const count of typeCounts) {
      offsets.push(total)
      total += count
    }
    const fontSize = total >= 1000 ? 16 : total >= 100 ? 15 : total >= 10 ? 14 : 12
    const r = total >= 1000 ? 32 : total >= 100 ? 26 : total >= 10 ? 18 : 16
    const r0 = Math.round(r * 0.6)
    const w = r * 2

    let html = `<div opacity="${opacity}">
        <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block" opacity="${opacity}">`

    for (let i = 0; i < typeCounts.length; i++) {
      html += this.donutSegment(offsets[i] / total, (offsets[i] + typeCounts[i]) / total, r, r0, colors[i])
    }
    html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="${Colors.Neutral_80}" opacity="${opacity}" />
            <text dominant-baseline="central" transform="translate(${r}, ${r})" font-family="${montserratFontFamily}" fill="${Colors.White}" opacity="${opacity}">
                ${pointCounts}
            </text>
        </svg>
      </div>`

    const el = document.createElement('div')
    el.innerHTML = html

    return el.firstChild as HTMLElement
  }

  private donutSegment = (start: number, end: number, r: number, r0: number, color: string) => {
    if (end - start === 1) end -= 0.00001
    const a0 = 2 * Math.PI * (start - 0.25)
    const a1 = 2 * Math.PI * (end - 0.25)
    const x0 = Math.cos(a0)
    const y0 = Math.sin(a0)
    const x1 = Math.cos(a1)
    const y1 = Math.sin(a1)
    const largeArc = end - start > 0.5 ? 1 : 0

    // draw an SVG path
    return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${
      r + r * y0
    } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${
      r + r0 * x1
    } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0}" fill="${color}" />`
  }
}
