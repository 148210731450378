import { useQuery } from '@tanstack/react-query'
import { Api } from '@/core/api'
import { useCurrentUser } from '@/core/hooks/useCurrentUser'

export const QUERY_KEY_INCIDENT_LOCATIONS = 'incidents-locations'

export const useIncidentLocationsData = () => {
  const { orgId } = useCurrentUser()
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEY_INCIDENT_LOCATIONS, orgId],
    queryFn: () => Api.mapEvents.getIncidentLocations({ orgId }),
    placeholderData: {},
    staleTime: Infinity,
  })

  return { data, incidentLocationsData: data, ...query }
}
