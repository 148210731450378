import { Montserrat } from 'next/font/google'

export const montserrat = Montserrat({
  weight: ['300', '400', '500', '600', '700'],
  preload: true,
  subsets: ['latin'],
  display: 'swap',
  fallback: ['sans-serif'],
})

export const montserratFontFamily = montserrat.style.fontFamily
