import React from 'react'
import { AssetItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { useLinkPreviewImageData } from '../../../hooks/useLinkPreviewImageData'
import { TargetCard } from '../TargetCard'

interface TargetAssetCardProps {
  target: Pick<
    AssetItem,
    | 'AssetName'
    | 'Address'
    | 'FocalPointEmail'
    | 'FocalPointName'
    | 'FocalPointPhone'
    | 'Hyperlink'
    | 'Latitude'
    | 'Longitude'
    | 'AssetDescription'
    | '_targetType'
  >
}

export const TargetAssetCard: React.FC<TargetAssetCardProps> = ({ target }) => {
  const { data, isLoading } = useLinkPreviewImageData({ link: target.Hyperlink })

  return (
    <div>
      <TargetCard
        details={{
          addressName: target.Address,
          coords: { lat: target.Latitude, lng: target.Longitude },
          contact: { email: target.FocalPointEmail, name: target.FocalPointName, phone: target.FocalPointPhone },
        }}
        imageSrc={data?.image}
        isImageLoading={isLoading}
        link={target.Hyperlink}
        description={target.AssetDescription}
      />
    </div>
  )
}
