'use client'

import React from 'react'
import {
  ChakraProvider,
  ColorModeProvider,
  ColorModeScript,
  ThemeConfig,
  ThemeProvider,
  baseTheme,
  extendTheme,
} from '@chakra-ui/react'
import { Theme } from '@chakra-ui/theme'
import { Colors } from '@/styles/colors'
import { montserratFontFamily } from '@/styles/fonts'
import './ChakraUILayer.scss'

const colors = {
  gray: {
    ...baseTheme.colors.gray,
    700: Colors.menuColor,
  },
} as Partial<(typeof baseTheme)['colors']>

const colorModeConfig: ThemeConfig = {
  useSystemColorMode: false,
  initialColorMode: 'dark',
}

const customTheme = extendTheme({
  config: {
    ...colorModeConfig,
  },
  styles: {
    global: {
      // README: body '/src/styles/globals.css'
      body: {
        background: '#1E1F32',
        backgroundImage: 'radial-gradient(#22202A, #1E1F32)',
        backgroundRepeat: 'no-repeat',
        color: '#FFFFFF',
        fontSize: '14px',
      },
    },
  },
  fonts: {
    body: montserratFontFamily,
    heading: montserratFontFamily,
    mono: montserratFontFamily,
  },
  colors: {
    ...baseTheme.colors,
    ...colors,
  },
} as Partial<Theme>)

export const ChakraUILayer: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div id="app-layer">
      <ChakraProvider theme={customTheme} cssVarsRoot="#app-layer" resetCSS disableGlobalStyle>
        <ThemeProvider theme={customTheme} cssVarsRoot="#app-layer">
          <ColorModeScript initialColorMode={colorModeConfig.initialColorMode} />
          <ColorModeProvider value={customTheme.config.initialColorMode} options={colorModeConfig}>
            {children}
          </ColorModeProvider>
        </ThemeProvider>
      </ChakraProvider>
    </div>
  )
}
