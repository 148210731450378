import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { useErrorToast } from '@/components/layers/ReactQueryProvider'
import { Api } from '@/core/api'
import { useCurrentUser } from '@/core/hooks/useCurrentUser'

export const QUERY_KEY_INCIDENTS = 'map-incidents'

export const useIncidentsData = ({ enabled = true }: { enabled?: boolean }) => {
  const { orgId } = useCurrentUser()
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEY_INCIDENTS, orgId],
    queryFn: () => Api.mapEvents.getIncidents({ orgId }),
    placeholderData: [],
    enabled,
  })

  useErrorToast(query)

  return { data, incidentsData: isEmpty(data) ? [] : data!, ...query }
}
