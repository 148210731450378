import React, { useEffect, useRef } from 'react'
import { Icon } from '@/components/common/Icon'
import { useIntersectionObserver } from '@/components/hooks/useIntersectionObserver'
import { IncidentFeedItem } from '@/server/services/GoogleBigQuery/BigQueryEventsClient.types'
import { Colors } from '@/styles/colors'
import { useMapEventsContext } from '../../../context/MapEventsContextProvider'
import { getIncidentTypeIdColor } from '../../utils/getIncidentTypeColor'
import { getRelativeTimeAgo, getTimeDate } from '../../utils/getTimeDate'
import styles from './IncidentFeedItem.module.scss'

interface IncidentFeedItemProps {
  item: IncidentFeedItem
  onIntersection?(): void
}

export const IncidentFeedItemView: React.FC<IncidentFeedItemProps> = ({ item, onIntersection }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { setActiveTarget } = useMapEventsContext()
  const time = getTimeDate(item.IncidentDateTime, {
    day: 'numeric',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
  })
  const timeAgo = getRelativeTimeAgo(item.IncidentDateTime)

  const entry = useIntersectionObserver(ref, { threshold: 0.25, once: true })

  useEffect(() => {
    if (onIntersection && entry?.isIntersecting) {
      onIntersection()
    }
  }, [entry?.isIntersecting, onIntersection])

  const onDetailsClick = () => setActiveTarget(item)
  const color = getIncidentTypeIdColor(item.IncidentTypeID)

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.header}>
        <div className={styles.marker} style={{ background: color }} />
        <span>{item.DataSource}</span>
        <a
          className="flex items-center gap-1"
          href={item.Hyperlink}
          target="_blank"
          rel="noreferrer"
          style={{ color: Colors.White, fontWeight: 400 }}
        >
          <span>Source</span>
          <Icon name="LaunchIcon" fillColor={Colors.White} size={12} />
        </a>
      </div>
      <div className={styles.body}>
        <div>{item.City}</div>
        <div className={styles.description}>{item.IncidentDescription}</div>
        <div className={styles.bodyFooter}>
          <div className={styles.time}>
            {time} | {timeAgo}
          </div>
          <div className={styles.link} onClick={onDetailsClick}>
            <span>View Details</span>
            <Icon name="ArrowRightIcon" fillColor={Colors.Blue} size={20} />
          </div>
        </div>
      </div>
    </div>
  )
}
