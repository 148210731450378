import { BasePanelProps } from '../../components/panels/BasePanel'

export interface PanelProps {
  animated?: BasePanelProps['animated']
  closePanel: BasePanelProps['onClickClose']
}

export enum PanelType {
  Dashboard = 'Dashboard',
  IncidentsFeed = 'IncidentsFeed',
  ActiveIncidents = 'ActiveIncidents',
  Filters = 'Filters',
}
